import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "@material-ui/core/Container"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { useIntl } from "gatsby-plugin-react-intl"

const NotFoundPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        description=""
        title={intl.formatMessage({
          id: "404_title",
        })}
      />
      <Container>
        <section className="not-found">
          <h1>
            <FormattedMessage id="404_title" defaultMessage="NIE ZNALEZIONO" />
          </h1>
          <p>
            <FormattedMessage
              id="404_message"
              defaultMessage="Taka strona nie istnieje w naszym serwisie..."
            />
          </p>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Site404($language: String) {
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
  }
`

export default NotFoundPage
